<template>
  <div v-cloak>
    <div class="wrap">

      <h1>Редактирование конфигурации<br/>чек-листа id 4830
      <v-btn outlined>загрузить файл конфигурации</v-btn>
      </h1>

      <div class="creation-date">Дата создания<span>13.01.2022</span></div>

      <h4>Наименование</h4>
      <v-text-field label="" value="Отчёт №1" dense></v-text-field>

      


                <div class="list config">


            <div class="list-item-wrap">

              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header inside @keyup.space.prevent>

                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span><h4>Группа показателей</h4><q>1</q>
                          <v-select></v-select>
                        </span>   
                                <div
                                     class="delete"
                                     >
                                    <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>                   
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>


                    <v-expansion-panels>
                      <v-expansion-panel class="level2">


                        <v-expansion-panel-header @keyup.space.prevent>
                          <div class="list-item">
                            <div class="list-item-name">
                              <button class="toggleSwitch whiteBg">
                                <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                </svg>
                                <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                </svg>
                              </button>
                              <span><h4>Показатель</h4><q>1</q><v-select></v-select></span>
                              <div
                                     class="delete"
                                     >
                                  <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>   
                            </div>
                          </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="level3">

                           <table class="config-table">
                              <tbody>
                              <tr>
                                <td>Тип</td>
                                <td>
                                  <v-select
                                      label="Комментарий"
                                  ></v-select>
                                </td>
                                <td>
                                  <p>Ответ на вопрос-параметр в формате комментария</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          
                          <div class="list-item-wrap">


                          <div class="list-item">
                            <div class="list-item-name">                              
                              <span><h4>Параметры</h4><q>1.</q><v-text-field></v-text-field></span>
                                <div
                                     class="delete"
                                     >
                                    <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>  
                            </div>
                          </div>

                            <v-row>
                            <v-col sm-6><v-checkbox label="Возможность прикрепления файлов"></v-checkbox></v-col>
                            <v-col sm-6><v-checkbox label="Только для управляющего ЦЗН"></v-checkbox></v-col>
                            </v-row>

                            
                          <div class="list-item">
                            <div class="list-item-name">                              
                              <span><q>2.</q><v-text-field></v-text-field></span>
                                <div
                                     class="delete"
                                     >
                                    <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>  
                            </div>
                          </div>
                                                    
                            <v-row>
                            <v-col sm-6><v-checkbox label="Возможность прикрепления файлов"></v-checkbox></v-col>
                            <v-col sm-6><v-checkbox label="Только для управляющего ЦЗН"></v-checkbox></v-col>
                            </v-row>

                            
                          <div class="list-item">
                            <div class="list-item-name">                              
                              <span><q>3.</q><v-text-field></v-text-field></span>
                                <div
                                     class="delete"
                                     >
                                    <v-icon class="icon-item icon-delete-outline"></v-icon>
                                </div>  
                            </div>
                          </div>
                                                    
                            <v-row>
                            <v-col sm-6><v-checkbox label="Возможность прикрепления файлов"></v-checkbox></v-col>
                            <v-col sm-6><v-checkbox label="Только для управляющего ЦЗН"></v-checkbox></v-col>
                            </v-row>

                          <a class="configAdd" href="#">+ Параметр</a>
                          </div>

                          

                          

                        </v-expansion-panel-content>
<a class="configAdd" href="#">+ Показатель</a>
                      </v-expansion-panel>


                    </v-expansion-panels>

            <a class="configAdd" href="#">+ Добавить группу показателей</a>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </div>


          </div>



      
          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/passport')">Отмена</v-btn>
            <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>



    </div>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .v-btn {
      margin-left: 20px;
    }
  }

  .row {
    margin-left: 17px;
  }
  
  .list.config {
    margin-top: 8px;
  }
</style>

<script>

export default {
  name: "indexClientEdit",
  props: {
  },
  components: {},
  data() {
    return {
    };
  }
};
</script>
